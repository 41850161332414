import Dexie from "dexie";

import { db } from "~/libs/db";

const additionalDataForDeliverylist = {
  /**
   * 個人メモ用画像を登録/更新する
   * @param {string} trackingNumber
   * @param {string} personalMemoPhoto
   */
  async registPersonalMemoPhoto(trackingNumber, personalMemoPhoto) {
    if (await hasRecord(trackingNumber)) {
      await update(trackingNumber, { personalMemoPhoto: personalMemoPhoto });
    } else {
      const record = {
        trackingNumber: trackingNumber,
        personalMemoPhoto: personalMemoPhoto,
      };
      await regist(record);
    }
  },

  /**
   * 送り状番号を指定して個人メモ用画像を取得する
   * @param {string} trackingNumber
   * @returns {Promise<string>} 個人メモ用画像(登録が無い場合は空文字)
   */
  async getPersonalMemoPhoto(trackingNumber) {
    if (await hasRecord(trackingNumber)) {
      return await get(trackingNumber).then(
        (record) => record?.personalMemoPhoto,
      );
    } else {
      return "";
    }
  },

  /**
   * 送り状番号を指定してデータを削除する
   * @param {string} trackingNumber
   */
  async deleteByTrackingNumber(trackingNumber) {
    if (await hasRecord(trackingNumber)) {
      await db.additionalDataForDeliverylist.delete(trackingNumber);
    }
  },

  /**
   * 全てのデータを削除する
   */
  async deleteAll() {
    const allRecords = await db.additionalDataForDeliverylist.toArray();
    allRecords.forEach((record) => {
      db.additionalDataForDeliverylist.delete(record.trackingNumber);
    });
  },
};
export default additionalDataForDeliverylist;

/**
 * レコードの有無を取得する
 * @param {string} trackingNumber
 * @returns {Promise<boolean>} レコード有無
 */
async function hasRecord(trackingNumber) {
  return (
    (await db.additionalDataForDeliverylist
      .where("trackingNumber")
      .equals(trackingNumber)
      .count()) > 0
  );
}

/**
 * データを取得する
 * @param {string} trackingNumber
 * @returns {Promise<import("~/libs/db").AdditionalDataForDeliverylist>}
 */
async function get(trackingNumber) {
  return await db.additionalDataForDeliverylist.get(trackingNumber);
}

/**
 * データを登録する
 * @param {import("~/libs/db").AdditionalDataForDeliverylist} record
 */
async function regist(record) {
  await db.additionalDataForDeliverylist.add(record);
}

/**
 * データを更新する
 * @param {string} trackingNumber
 * @param {{personalMemoPhoto: string}} change
 */
async function update(trackingNumber, change) {
  await db.additionalDataForDeliverylist.update(trackingNumber, change);
}
